import Matrix3D			= require("awayjs-core/lib/geom/Matrix3D");
import MaterialPassData = require("awayjs-renderergl/lib/pool/MaterialPassData");
import RendererBase		= require("awayjs-renderergl/lib/base/RendererBase");
import Texture2DBase    = require("awayjs-core/lib/textures/Texture2DBase");
import Camera			= require("awayjs-display/lib/entities/Camera");
import ContextWebGL     = require("awayjs-stagegl/lib/base/ContextWebGL");
import ProgramWebGL     = require("awayjs-stagegl/lib/base/ProgramWebGL");
import RenderableBase   = require("awayjs-renderergl/lib/pool/RenderableBase");
import Stage			= require("awayjs-stagegl/lib/base/Stage")
import SubGeometryBase = require("awayjs-display/lib/base/SubGeometryBase");
import TriangleSubGeometry = require("awayjs-display/lib/base/TriangleSubGeometry");
import GLSLMaterialPass = require("./../glsl/GLSLMaterialPass");


class GlobePass extends GLSLMaterialPass
{
    static VERTEX_CODE:string = "\
        precision mediump float;\n\
        \n\
        attribute vec4 position;\n\
        attribute vec2 uv;\n\
        \n\
        uniform mat4 wvpMatrix;\n\
        \n\
        varying vec2 uvVar;\n\
        \n\
        void main() {\n\
            gl_Position = wvpMatrix * position;\n\
            uvVar = uv;\n\
        }\
    ";

    static FRAGMENT_CODE:string = "\
        precision mediump float;\n\
        \n\
        varying vec2 uvVar;\n\
        uniform sampler2D textureSampler;\n\
        \n\
        void main() {\n\
            gl_FragColor = texture2D(textureSampler, uvVar);\n\
        }\
    ";

    private _wvpPosition : WebGLUniformLocation;
    private _positionAttribute : number;
    private _uvAttribute : number;
    private _matrix : Matrix3D = new Matrix3D();
    private _gl : WebGLRenderingContext;
    private _texture : Texture2DBase;

    constructor()
    {
        super(GlobePass.VERTEX_CODE, GlobePass.FRAGMENT_CODE);
    }

    public get texture() : Texture2DBase
    {
        return this._texture;
    }

    public set texture(value : Texture2DBase)
    {
        this._texture = value;
    }

    public _iActivate(pass:MaterialPassData, renderer:RendererBase, camera:Camera)
    {
        renderer.activateMaterialPass(pass, camera);

        this._gl = (<ContextWebGL>renderer._pContext).gl();
        var program : WebGLProgram = (<ProgramWebGL>pass.programData.program)["_program"];

        if (!this._wvpPosition) {
            this._wvpPosition = this._gl.getUniformLocation(program, "wvpMatrix");
            this._positionAttribute = this._gl.getAttribLocation(program, "position");
            this._uvAttribute = this._gl.getAttribLocation(program, "uv");
            var textureIndex = this._gl.getUniformLocation(program, "textureSampler");
            this._gl.uniform1i(textureIndex, 0);
        }

        this._gl.enable(this._gl.BLEND);
        this._gl.blendFunc(this._gl.SRC_ALPHA, this._gl.ONE);
        this._gl.blendEquation(this._gl.FUNC_ADD);
        this._gl.depthMask(false);
    }

    public setRenderState(pass:MaterialPassData, renderable:RenderableBase, stage:Stage, camera:Camera, viewProjection:Matrix3D)
    {
        this._matrix.copyFrom(renderable.renderSceneTransform);
        this._matrix.append(viewProjection);

        var f32 : Float32Array = new Float32Array(this._matrix.rawData);
        this._gl.uniformMatrix4fv(this._wvpPosition, false, f32);

        stage.activateBuffer(this._positionAttribute, renderable.getVertexData(TriangleSubGeometry.POSITION_DATA), renderable.getVertexOffset(TriangleSubGeometry.POSITION_DATA), "float3");
        stage.activateBuffer(this._uvAttribute, renderable.getVertexData(TriangleSubGeometry.UV_DATA), renderable.getVertexOffset(TriangleSubGeometry.UV_DATA), "float2");
        stage.activateTexture(0, this._texture);
        stage.context.drawTriangles(stage.getIndexBuffer(renderable.getIndexData()), 0, renderable.numTriangles);

    }

    public _iDeactivate(pass:MaterialPassData, renderer:RendererBase)
    {
        renderer.deactivateMaterialPass(pass);

        this._gl.disable(this._gl.BLEND);
        this._gl.depthMask(true);
    }
}

export = GlobePass;