import WorldDataElement = require("./WorldDataElement");

class WorldDataSet
{
    public elements:Array<WorldDataElement>;
    public maxElementValue:number = 0;
    public averageElementValue:number = 0;

    constructor()
    {
        this.elements = new Array<WorldDataElement>();
    }
}

export = WorldDataSet;
