import Matrix3D			= require("awayjs-core/lib/geom/Matrix3D");
import MaterialPassData = require("awayjs-renderergl/lib/pool/MaterialPassData");
import RendererBase		= require("awayjs-renderergl/lib/base/RendererBase");
import Camera			= require("awayjs-display/lib/entities/Camera");
import ContextWebGL     = require("awayjs-stagegl/lib/base/ContextWebGL");
import ProgramWebGL     = require("awayjs-stagegl/lib/base/ProgramWebGL");
import RenderableBase   = require("awayjs-renderergl/lib/pool/RenderableBase");
import Stage			= require("awayjs-stagegl/lib/base/Stage")
import SubGeometryBase = require("awayjs-display/lib/base/SubGeometryBase");
import TriangleSubGeometry = require("awayjs-display/lib/base/TriangleSubGeometry");
import GLSLMaterialPass = require("./../glsl/GLSLMaterialPass");
import DataVisRenderable = require("./../geometry/DataVisRenderable");
import DataVisSubRenderable = require("./../geometry/DataVisSubRenderable");


class DataVisPass extends GLSLMaterialPass
{
    static VERTEX_CODE:string = "\
        precision mediump float;\n\
        \n\
        attribute vec2 latLongPosition;\n\
        attribute vec3 offsetPosition;\n\
        \n\
        attribute float valueHour1;\n\
        attribute float valueHour2;\n\
        \n\
        uniform mat4 wvpMatrix;\n\
        uniform float sphereRadius;\n\
        uniform float hourInterpolation;\n\
        uniform vec3 minColor;\n\
        uniform vec3 maxColor;\n\
        uniform float rcpMaxValue;\n\
        \n\
        varying vec3 color;\n\
        \n\
        void main() {\n\
            float latitude = latLongPosition.x;\n\
            float longitude = latLongPosition.y;\n\
            float cosX = cos(latitude);\n\
            float sinX = sin(latitude);\n\
            float cosY = cos(longitude);\n\
            float sinY = sin(longitude);\n\
            // todo: could encode this in normal/tangent buffer, calc zAxis dynamically\n\
            vec3 yAxis = vec3(-sinY*sinX, cosX, sinX * cosY);\n\
            vec3 zAxis = vec3(-sinY*cosX, -sinX, cosX * cosY);\n\
            vec3 xAxis = cross(yAxis, zAxis);\n\
            mat3 rotation;\
            rotation = mat3(xAxis, yAxis, zAxis);\n\
            float value = mix(valueHour1, valueHour2, hourInterpolation);\n\
            float ratio = value * rcpMaxValue;\n\
            vec3 localPos = offsetPosition*ratio;\n\
            localPos.y = localPos.y*0.2;\n\
            localPos.x = localPos.x*0.2;\n\
            \n\
            color = mix(minColor, maxColor, clamp(ratio, 0.0, 1.0));\n\
            color = color * mix(minColor, maxColor, clamp(localPos.z, 0.8, 1.0));\n\
            localPos.z += sphereRadius;\n\
            vec4 rotatedPos = vec4(rotation * localPos, 1.0);\n\
            gl_Position = wvpMatrix * rotatedPos;\n\
        }\
    ";

    static FRAGMENT_CODE:string = "\
        precision mediump float;\n\
        \n\
        varying vec3 color;\n\
        \n\
        void main() {\n\
            gl_FragColor = vec4(color, 1.0);\n\
        }\
    ";

    private _wvpPosition : WebGLUniformLocation;
    private _sphereRadiusPosition : WebGLUniformLocation;
    private _minColorLocation : WebGLUniformLocation;
    private _maxColorLocation : WebGLUniformLocation;
    private _hourInterpolationPosition : WebGLUniformLocation;
    private _latLongAttribute : number;
    private _valueHour1Attribute : number;
    private _valueHour2Attribute : number;
    private _offsetPositionAttribute : number;
    private _radiusDirty : boolean = true;
    private _hourDirty : boolean = true;
    private _colorsDirty : boolean = true;
    private _maxValueDirty : boolean = true;
    private _wvpMatrix : Matrix3D = new Matrix3D();
    private _gl : WebGLRenderingContext;
    private _sphereRadius : number = 10.0;
    private _maxValue : number = 1.0;
    private _dataVisRenderable: DataVisRenderable;
    private _hour : number = 0;
    private _minColor : number = 0x1A3056;
    private _maxColor : number = 0x83b1ff;

    constructor(useGradients : boolean = false)
    {
        super((useGradients? "#define USE_GRADIENTS\n" : "") + DataVisPass.VERTEX_CODE, DataVisPass.FRAGMENT_CODE);
    }

    public get minColor():number
    {
        return this._minColor;
    }

    public set minColor(value:number)
    {
        this._minColor = value;
        this._colorsDirty = true;
    }

    public get maxColor():number
    {
        return this._maxColor;
    }

    public set maxColor(value:number)
    {
        this._maxColor = value;
    }

    get dataVisRenderable() : DataVisRenderable
    {
        return this._dataVisRenderable;
    }

    set dataVisRenderable(value : DataVisRenderable)
    {
        this._dataVisRenderable = value;
    }

    public get sphereRadius() : number { return this._sphereRadius; }
    public set sphereRadius(value : number)
    {
        this._sphereRadius = value;
        this._radiusDirty = true;
    }

    public get maxValue() : number { return this._maxValue; }
    public set maxValue(value : number)
    {
        this._maxValue = value;
        this._maxValueDirty = true;
    }

    public get hour() : number { return this._hour; }
    public set hour(value : number)
    {
        this._hour = value;
        this._hourDirty = true;
    }

    public _iActivate(pass:MaterialPassData, renderer:RendererBase, camera:Camera)
    {
        if (!this._dataVisRenderable) return;

        renderer.activateMaterialPass(pass, camera);

        this._gl = (<ContextWebGL>renderer._pContext).gl();
        var program : WebGLProgram = (<ProgramWebGL>pass.programData.program)["_program"];

        if (!this._wvpPosition) {
            this._sphereRadiusPosition = this._gl.getUniformLocation(program, "sphereRadius");
            this._hourInterpolationPosition = this._gl.getUniformLocation(program, "hourInterpolation");
            this._wvpPosition = this._gl.getUniformLocation(program, "wvpMatrix");
            this._latLongAttribute = this._gl.getAttribLocation(program, "latLongPosition");
            this._offsetPositionAttribute = this._gl.getAttribLocation(program, "offsetPosition");
            this._valueHour1Attribute = this._gl.getAttribLocation(program, "valueHour1");
            this._valueHour2Attribute = this._gl.getAttribLocation(program, "valueHour2");
            var program : WebGLProgram = (<ProgramWebGL>pass.programData.program)["_program"];
            this._minColorLocation = this._gl.getUniformLocation(program, "minColor");
            this._maxColorLocation = this._gl.getUniformLocation(program, "maxColor");
        }

        if (this._maxValueDirty) {
            console.log("Changing max value to " + this._maxValue);
            this._gl.uniform1f(this._gl.getUniformLocation(program, "rcpMaxValue"), this._maxValue == 0? 1.0 : 1.0 / this._maxValue);
            this._maxValueDirty = false;
        }

        if (this._radiusDirty) {
            this._gl.uniform1f(this._sphereRadiusPosition, this._sphereRadius);
            this._radiusDirty = false;
        }

        if (this._hourDirty) {
            this._gl.uniform1f(this._hourInterpolationPosition, this._hour - Math.floor(this._hour));
            this._hourDirty = false;
        }

        if (this._colorsDirty) {
            this._gl.uniform3f(this._minColorLocation, (this._minColor >> 16) / 0xff*0.3, ((this._minColor >> 8) & 0xff) / 0xff*0.3, (this._minColor & 0xff) / 0xff*0.3);
            this._gl.uniform3f(this._maxColorLocation, (this._maxColor >> 16) / 0xff*1.3, ((this._maxColor >> 8) & 0xff) / 0xff*1.3, (this._maxColor & 0xff) / 0xff*1.3);
        }

        this._gl.enableVertexAttribArray(this._offsetPositionAttribute);
        this._gl.enableVertexAttribArray(this._latLongAttribute);
        this._gl.enableVertexAttribArray(this._valueHour1Attribute);
        this._gl.enableVertexAttribArray(this._valueHour2Attribute);
    }

    public setRenderState(pass:MaterialPassData, renderable:RenderableBase, stage:Stage, camera:Camera, viewProjection:Matrix3D)
    {
        if (!this._dataVisRenderable) return;

        this._wvpMatrix.copyFrom(renderable.renderSceneTransform);
        this._wvpMatrix.append(viewProjection);
        var f32 : Float32Array = new Float32Array(this._wvpMatrix.rawData);
        this._gl.uniformMatrix4fv(this._wvpPosition, false, f32);

        var len = this._dataVisRenderable.numSubRenderables;
        for (var i = 0; i < len; ++i) {
            var subRenderable : DataVisSubRenderable = this._dataVisRenderable.getSubRenderable(i);
            var vertexPosBuffer : WebGLBuffer = subRenderable.getVertexPositionBuffer(this._gl);
            var vertexHourlyBuffer : WebGLBuffer = subRenderable.getVertexHourlyBuffer(this._gl);
            var indexBuffer : WebGLBuffer = subRenderable.getIndexBuffer(this._gl);

            this._gl.bindBuffer(this._gl.ARRAY_BUFFER, vertexPosBuffer);
            this._gl.vertexAttribPointer(this._offsetPositionAttribute, 3, this._gl.FLOAT, false, 20, 0);
            this._gl.vertexAttribPointer(this._latLongAttribute, 2, this._gl.FLOAT, false, 20, 12);

            var currentHour = Math.floor(this._hour);
            var nextHour = currentHour + 1;
            if (nextHour == 24) nextHour = 0;

            this._gl.bindBuffer(this._gl.ARRAY_BUFFER, vertexHourlyBuffer);
            this._gl.vertexAttribPointer(this._valueHour1Attribute, 1, this._gl.FLOAT, false, 96, currentHour * 4);
            this._gl.vertexAttribPointer(this._valueHour2Attribute, 1, this._gl.FLOAT, false, 96, nextHour * 4);

            this._gl.bindBuffer(this._gl.ELEMENT_ARRAY_BUFFER, indexBuffer);
            this._gl.drawElements(this._gl.TRIANGLES, subRenderable.numTriangles * 3, this._gl.UNSIGNED_SHORT, 0);
        }
    }

    public _iDeactivate(pass:MaterialPassData, renderer:RendererBase)
    {
        renderer.deactivateMaterialPass(pass);

        this._gl.disableVertexAttribArray(this._offsetPositionAttribute);
        this._gl.disableVertexAttribArray(this._latLongAttribute);
        this._gl.disableVertexAttribArray(this._valueHour1Attribute);
        this._gl.disableVertexAttribArray(this._valueHour2Attribute);
    }
}

export = DataVisPass;