import IAsset                               = require("awayjs-core/lib/library/IAsset");
import Event                                = require("awayjs-core/lib/events/Event");
import WorldDataElement                     = require("./WorldDataElement");
import WorldDataSet                         = require("./WorldDataSet");

class WorldDataEvent extends Event {
    public static LOADED:string = "loadEvent";
    public static NO_COUNTRY_DATA:string = "noCountryData";
    public static NO_VENDOR_DATA:string = "noVendorData";
    public static FIND_LOCATION:string = "findLocation";
    public static SHOW_GLOBAL_LOCATION:string = "showGlobalLocation";

    public data:WorldDataSet;
    public lat:number;
    public long:number;
    public x:number;
    public y:number;
    public z:number;
    public sphereRadius:number;

    constructor(type:string) {
        super(type);
    }

    public clone():Event {
        return <Event> new WorldDataEvent(this.type);
    }
}

export = WorldDataEvent;