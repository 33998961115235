import DefaultRenderer = require("awayjs-renderergl/lib/DefaultRenderer");
import AnimationSetBase = require("awayjs-renderergl/lib/animators/AnimationSetBase");
import ShaderObjectBase = require("awayjs-renderergl/lib/compilation/ShaderObjectBase");
import MaterialPassData = require("awayjs-renderergl/lib/pool/MaterialPassData");
import Camera = require("awayjs-display/lib/entities/Camera");
import ProgramData = require("awayjs-stagegl/lib/pool/ProgramData");
import ByteArray = require("awayjs-core/lib/utils/ByteArray");
import AGALMiniAssembler = require("awayjs-stagegl/lib/aglsl/assembler/AGALMiniAssembler");
import ContextMode = require("awayjs-display/lib/display/ContextMode");
import MaterialGLBase = require("awayjs-renderergl/lib/materials/MaterialGLBase");
import MaterialData = require("awayjs-renderergl/lib/pool/MaterialData");

class GLSLRenderer extends DefaultRenderer
{
    private __numUsedStreams:number = 0;
    private __numUsedTextures:number = 0;

    constructor()
    {
        super(false, "baseline", ContextMode.NATIVE);
    }

    public getMaterial(material:MaterialGLBase, profile:string):MaterialData
    {
        var materialData:MaterialData = this["_materialDataPool"].getItem(material);

        if (materialData.invalidAnimation) {
            materialData.invalidAnimation = false;

            var materialDataPasses:Array<MaterialPassData> = materialData.getMaterialPasses(profile);

            var renderOrderId = 0;
            var mult:number = 1;
            var materialPassData:MaterialPassData;
            var len:number = materialDataPasses.length;
            for (var i:number = 0; i < len; i++) {
                materialPassData = materialDataPasses[i];
                renderOrderId += this.getProgram(materialPassData).id*mult;
                mult *= 1000;
            }

            materialData.renderOrderId = renderOrderId;
        }

        return materialData;
    }

    public activateMaterialPass(materialPassData:MaterialPassData, camera:Camera)
    {
        var shaderObject:ShaderObjectBase = materialPassData.shaderObject;

        //clear unused vertex streams
        for (var i = shaderObject.numUsedStreams; i < this.__numUsedStreams; i++)
            this._pContext.setVertexBufferAt(i, null);

        //clear unused texture streams
        for (var i = shaderObject.numUsedTextures; i < this.__numUsedTextures; i++)
            this._pContext.setTextureAt(i, null);

        //activate shader object
        shaderObject.iActivate(this._pStage, camera);

        //check program data is uploaded
        var programData:ProgramData = this.getProgram(materialPassData);

        if (!programData.program) {
            programData.program = this._pContext.createProgram();
            var vertexByteCode:ByteArray = this.stringToByteArray(materialPassData.vertexCode);
            var fragmentByteCode:ByteArray = this.stringToByteArray(materialPassData.fragmentCode);

            programData.program.upload(vertexByteCode, fragmentByteCode);
        }

        //set program data
        this._pContext.setProgram(programData.program);
    }

    private stringToByteArray(value:string) : ByteArray
    {
        var len = value.length;
        var ba : ByteArray = new ByteArray();
        ba.writeUnsignedInt(len);
        for (var i = 0; i < len; ++i) {
            ba.writeByte(value.charCodeAt(i));
        }

        return ba;
    }

    public deactivateMaterialPass(materialPassData:MaterialPassData)
    {
        var shaderObject:ShaderObjectBase = materialPassData.shaderObject;

        if (materialPassData.usesAnimation)
            (<AnimationSetBase> materialPassData.material.animationSet).deactivate(shaderObject, this._pStage);

        materialPassData.shaderObject.iDeactivate(this._pStage);

        this.__numUsedStreams = shaderObject.numUsedStreams;
        this.__numUsedTextures = shaderObject.numUsedTextures;
    }
}
export = GLSLRenderer;