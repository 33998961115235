import ShaderCompilerBase = require("awayjs-renderergl/lib/compilation/ShaderCompilerBase");

class GLSLCompiler extends ShaderCompilerBase
{
    public compile()
    {
        this._pVertexCode = this._pMaterialPass._iGetVertexCode(this._pShaderObject, this._pRegisterCache, this._pSharedRegisters);
        this._pFragmentCode = this._pMaterialPass._iGetFragmentCode(this._pShaderObject, this._pRegisterCache, this._pSharedRegisters);
    }

    public get postAnimationFragmentCode():string
    {
        return "";
    }

    /**
     * The register name containing the final shaded colour.
     */
    public get shadedTarget():string
    {
        return "";
    }
}
export = GLSLCompiler;
