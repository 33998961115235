import EventDispatcher = require("awayjs-core/lib/events/EventDispatcher");
import WorldDataEvent = require("./WorldDataEvent");
import WorldDataElement = require("./WorldDataElement");
import WorldDataSet = require("./WorldDataSet");

import URLLoader            = require("awayjs-core/lib/net/URLLoader");
import URLLoaderDataFormat    = require("awayjs-core/lib/net/URLLoaderDataFormat");
import URLRequest            = require("awayjs-core/lib/net/URLRequest");
import URLRequestMethod        = require("awayjs-core/lib/net/URLRequestMethod");
import Event                = require("awayjs-core/lib/events/Event");
import IOErrorEvent            = require("awayjs-core/lib/events/IOErrorEvent");
import HTTPStatusEvent        = require("awayjs-core/lib/events/HTTPStatusEvent");
import ByteArray            = require("awayjs-core/lib/utils/ByteArray");

class WorldData extends EventDispatcher {

    private urlLoader:URLLoader;
    private loadedCountries:Object = {};
    private loadedHandsets:Object = {};

    private currentView:string;
    private currentHandSet:string;
    private currentTimeType:string;

    private emptyData:WorldDataSet;

    constructor() {
        super();
    }

    public load(view:string, handSet:string, timeType:string):void {
        this.currentView = view;
        this.currentHandSet = handSet;
        this.currentTimeType = timeType;

        if (this.urlLoader) {
            this.urlLoader.dispose();
            this.urlLoader = null;
        }
        //
        //if (view == "0") {
        //    TODO: remove when gloval data will be avaliable
        //this.sendTestGlobalData();
        //return;
        //}

        this.generateEmptyData();

        if (!this.loadedCountries[view]) {
            this.loadCountry();
        } else {
            this.checkHandSet();
        }
    }


    public getNearestLocation(sourceX:number, sourceY:number, sourceZ:number, sphereRadius:number):string {
        var bestX:number = 0;
        var bestY:number = 0;
        var bestZ:number = 0;
        var distance:Number = 100000000;
        var bestKey:number;

        var country:any = this.loadedCountries["0"];
        var positions:Array<number> = country.data;
        var countryIds:Array<number> = country.countryIds;
        var len:number = country.numLocations;
        for(var i:number = 0; i<len; i++) {
            var dlat:number = -positions[i * 2];
            var dlong:number = positions[i * 2+1];

            var radX : number = dlat * Math.PI / 180;
            var radY : number = dlong * Math.PI / 180;
            var sinX : number = Math.sin(radX);
            var cosX : number = Math.cos(radX);
            var sinY : number = Math.sin(radY);
            var cosY : number = Math.cos(radY);

            var x:number = -sinY * cosX * sphereRadius;
            var y:number = -sinX * sphereRadius;
            var z:number = cosY * cosX * sphereRadius;

            var deltaX:number = x - sourceX;
            var deltaY:number = y - sourceY;
            var deltaZ:number = z - sourceZ;
            var d:number = Math.sqrt(deltaX*deltaX+deltaY*deltaY+deltaZ*deltaZ);
            if(d<distance) {
                bestKey = countryIds[i];
                distance = d;
                bestX = x;
                bestY = y;
                bestZ = z;
            }
        }
        return bestKey.toString();
    }

    private generateEmptyData():void {
        this.emptyData = new WorldDataSet();
        var element:WorldDataElement = new WorldDataElement();
        element.latitude = 0;
        element.longitude = 0;

        var hours:Array<number> = new Array<number>();
        for (var j:number = 0; j < 24; j++) {
            hours[j] = 0;
        }
        element.hourlyValues = hours;

        this.emptyData.averageElementValue = 0;
        this.emptyData.maxElementValue = 0;

        this.emptyData.elements.push(element);
    }

    private loadCountry():void {
        this.urlLoader = new URLLoader();
        this.urlLoader.dataFormat = URLLoaderDataFormat.ARRAY_BUFFER;
        this.urlLoader.addEventListener(IOErrorEvent.IO_ERROR, (event:IOErrorEvent) => this.ioErrorCountry(event));
        this.urlLoader.addEventListener(Event.COMPLETE, (event:Event) => this.onLocationLoaded(event));
        this.urlLoader.load(new URLRequest('assets/locations/' + this.currentView+'.bin'));
    }

    private onLocationLoaded(event:Event):void {
        var loader:URLLoader = <URLLoader> event.target;
        var bytes:ByteArray = new ByteArray();
        bytes.setArrayBuffer(loader.data);
        bytes.position = 0;

        if (!bytes) {
            console.error("WorldData::onLocationLoaded. Locations " + this.currentView + " is not defined");
            return;
        }

        var positions:Array<number> = new Array<number>();
        var counts:Array<number> = new Array<number>();
        var countryIds:Array<number> = new Array<number>();

        //var len:number = Math.min(15000, bytes.length / 12);
        var len:number = bytes.length / 13;
        var minLat:number = 100000;
        var maxLat:number = -100000;
        var minLong:number = 100000;
        var maxLong:number = -100000;

        var k:number = 0;
        for (var i:number = 0; i < len; i++) {
            var lat:number = bytes.readFloat();
            var long:number = bytes.readFloat();
            minLat = Math.min(minLat, lat);
            maxLat = Math.max(maxLat, lat);
            minLong = Math.min(minLong, long);
            maxLong = Math.max(maxLong, long);
            positions[k++] = lat;
            positions[k++] = long;
            counts[i] = bytes.readUnsignedInt();
            countryIds[i] = bytes.readUnsignedByte();
        }

        this.loadedCountries[this.currentView] = {
            numLocations: len,
            data: positions,
            counts: counts,
            countryIds: countryIds,
            avgLat:(minLat+maxLat)*.5,
            avgLong:(minLong+maxLong)*.5
        };

        bytes = null;

        if (loader) {
            loader.dispose();
            loader = null;
        }

        this.checkHandSet();
    }

    private checkHandSet():void {
        //this.loadTestHandSet();
        //return;
        var key:string = this.currentView + "/" + this.currentHandSet;
        if (!this.loadedHandsets[key]) {
            this.urlLoader = new URLLoader();
            this.urlLoader.dataFormat = URLLoaderDataFormat.ARRAY_BUFFER;
            this.urlLoader.addEventListener(IOErrorEvent.IO_ERROR, (event:IOErrorEvent) => this.ioError(event));
            this.urlLoader.addEventListener(Event.COMPLETE, (event:Event) => this.onHandSetLoaded(event));
            this.urlLoader.load(new URLRequest('assets/counts/' + key+'.bin'));
        } else {
            this.loadHandSet();
        }
    }

    private loadTestHandSet():void {
        var country:any = this.loadedCountries[this.currentView];
        var positions:Array<number> = country.data;

        var key:string = this.currentView + "/" + this.currentHandSet;
        var countHourData:WorldDataSet = new WorldDataSet();
        this.loadedHandsets[key] = countHourData;

        var len:number = country.numLocations;
        var k:number = 0;
        var average:number = 0;
        var max:number = 0;

        for (var i:number = 0; i < len; i++) {
            var total : number = 0;
            var element:WorldDataElement = new WorldDataElement();
            element.latitude = -positions[i * 2];
            element.longitude = positions[i * 2+1];


            var hours:Array<number> = new Array<number>();
            for (var j:number = 0; j < 24; j++) {
                var value:number = .1 + Math.abs(Math.pow((Math.sin((j + element.latitude + element.longitude * .7) / 30) + Math.cos(element.latitude * element.longitude / 1000 + j)) * .5, 3));
                hours[j] = value;
                total += value;
                if (value > max) max = value;
            }
            element.hourlyValues = hours;
            if(total == 0) {
                k++;
            }

            countHourData.elements.push(element);

            average += total;
        }

        // TODO: could calculate avg lat/long here as well, so 3D view doesn't have to loop again
        countHourData.maxElementValue = max;
        countHourData.averageElementValue = average / (len * 24);   // who knows, this might be useful to detect outliers

        //console.log("Len: "+countHourData.length);
        //console.log("K: "+k);
        //average /= 24;
        //console.log("WorldData. Average value: "+average);

        this.loadHandSet();
    }

    private onHandSetLoaded(event:Event):void {
        var loader:URLLoader = <URLLoader> event.target;
        var bytes:ByteArray = new ByteArray();
        bytes.setArrayBuffer(loader.data);

        if (!bytes) {
            console.error("WorldData::onHandSetLoaded. HandSet " + this.currentHandSet + " is not defined for view id(country): " + this.currentView);
            return;
        }

        var country:any = this.loadedCountries[this.currentView];
        var positions:Array<number> = country.data;

        var key:string = this.currentView + "/" + this.currentHandSet;
        var countHourData:WorldDataSet = new WorldDataSet();
        this.loadedHandsets[key] = countHourData;

        var len:number = country.numLocations;
        var k:number = 0;
        var average:number = 0;
        var max:number = 0;

        for (var i:number = 0; i < len; i++) {
            var total : number = 0;
            var element:WorldDataElement = new WorldDataElement();
            element.latitude = -positions[i * 2];
            element.longitude = positions[i * 2+1];

            var hours:Array<number> = new Array<number>();
            for (var j:number = 0; j < 24; j++) {
                var value:number = bytes.readUnsignedByte();
                hours[j] = value;
                total += value;
                if (value > max) max = value;
            }
            element.hourlyValues = hours;
            if(total == 0) {
                k++;
            }

            countHourData.elements.push(element);

            average += total;
        }

        // TODO: could calculate avg lat/long here as well, so 3D view doesn't have to loop again
        countHourData.maxElementValue = max;
        countHourData.averageElementValue = average / (len * 24);   // who knows, this might be useful to detect outliers

        //console.log("Len: "+countHourData.length);
        //console.log("K: "+k);
        //average /= 24;
        //console.log("WorldData. Average value: "+average);

        bytes = null;

        if (loader) {
            loader.dispose();
            loader = null;
        }

        this.loadHandSet();
    }

    private loadHandSet():void {
        var worldEvent:WorldDataEvent = new WorldDataEvent(WorldDataEvent.LOADED);
        var key:string = this.currentView + "/" + this.currentHandSet;
        worldEvent.data = this.loadedHandsets[key];
        this.dispatchEvent(worldEvent);
    }

    private ioErrorCountry(event:IOErrorEvent):void {
        var loader:URLLoader = <URLLoader> event.target;
        console.error('WorldData.ioErrorCountry', loader.url);
        var worldEvent:WorldDataEvent = new WorldDataEvent(WorldDataEvent.NO_COUNTRY_DATA);
        worldEvent.data = this.emptyData;
        this.dispatchEvent(worldEvent);

    }

    private ioError(event:IOErrorEvent):void {
        var loader:URLLoader = <URLLoader> event.target;
        console.error('WorldData.ioError', loader.url);

        var worldEvent:WorldDataEvent = new WorldDataEvent(WorldDataEvent.NO_VENDOR_DATA);

        var country:any = this.loadedCountries[this.currentView];
        var positions:Array<number> = country.data;

        var key:string = this.currentView + "/" + this.currentHandSet;
        if(!this.loadedHandsets[key]) {
            var countHourData:WorldDataSet = new WorldDataSet();
            this.loadedHandsets[key] = countHourData;

            var len:number = country.numLocations;
            for (var i:number = 0; i < len; i++) {
                var element:WorldDataElement = new WorldDataElement();
                element.latitude = positions[i * 2];
                element.longitude = positions[i * 2 + 1];

                var hours:Array<number> = new Array<number>();
                for (var j:number = 0; j < 24; j++) {
                    hours[j] = 0;
                }
                element.hourlyValues = hours;
                countHourData.elements.push(element);
            }

            countHourData.maxElementValue = 0;
            countHourData.averageElementValue = 0;
        }

        worldEvent.data = this.loadedHandsets[key];
        this.dispatchEvent(worldEvent);
    }

}
export = WorldData;