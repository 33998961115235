import Stage = require("awayjs-stagegl/lib/base/Stage");
import ContextMode = require ("awayjs-display/lib/display/ContextMode");
import ContextWebGL = require ("awayjs-stagegl/lib/base/ContextWebGL");
import IContextGL = require ("awayjs-stagegl/lib/base/IContextGL");
import Event = require ("awayjs-core/lib/events/Event");
import ContextStage3D = require ("awayjs-stagegl/lib/base/ContextStage3D");
import ContextGLSL = require ("./ContextGLSL");

// MASSIVE HACK
class StageHack
{
    static INIT()
    {
        Stage.prototype.requestContext = function (forceSoftware:boolean = false, profile:string = "baseline", mode:string = "auto")
        {
            if (this._usesSoftwareRendering != null)
                this._usesSoftwareRendering = forceSoftware;

            this._profile = profile;

            try {
                if (mode == ContextMode.FLASH)
                    new ContextStage3D(<HTMLCanvasElement> this._container, (context:IContextGL) => this._callback(context));
                else if (mode == ContextMode.WEBGL)
                    this._context = new ContextWebGL(<HTMLCanvasElement> this._container);
                else if (mode == ContextMode.NATIVE) {
                    this._context = new ContextGLSL(<HTMLCanvasElement> this._container);
                }

            } catch (e) {
                try {
                    if (mode == ContextMode.AUTO)
                        new ContextStage3D(<HTMLCanvasElement> this._container, (context:IContextGL) => this._callback(context));
                    else
                        this.dispatchEvent(new Event(Event.ERROR));
                } catch (e) {
                    this.dispatchEvent(new Event(Event.ERROR));
                }

            }

            if (this._context)
                this._callback(this._context);
        };
    }
}
export = StageHack;