import ShaderObjectBase = require("awayjs-renderergl/lib/compilation/ShaderObjectBase");
import ShaderRegisterCache = require("awayjs-renderergl/lib/compilation/ShaderRegisterCache");
import ShaderRegisterData = require("awayjs-renderergl/lib/compilation/ShaderRegisterData");
import MaterialPassGLBase = require("awayjs-renderergl/lib/passes/MaterialPassGLBase");
import GLSLShaderObject = require("./GLSLShaderObject");

class GLSLMaterialPass extends MaterialPassGLBase
{
    private _vertexCode : string;
    private _fragmentCode : string;

    constructor(vertexCode:string, fragmentCode:string)
    {
        super();
        this._vertexCode = vertexCode;
        this._fragmentCode = fragmentCode;
    }

    _iGetVertexCode(shaderObject: ShaderObjectBase, registerCache: ShaderRegisterCache, sharedRegisters: ShaderRegisterData): string
    {
        return this._vertexCode;
    }

    _iGetFragmentCode(shaderObject: ShaderObjectBase, registerCache: ShaderRegisterCache, sharedRegisters: ShaderRegisterData): string
    {
        return this._fragmentCode;
    }

    createShaderObject(profile: string): ShaderObjectBase
    {
        return new GLSLShaderObject(profile);
    }
}

export = GLSLMaterialPass;