import TriangleSubGeometry = require("awayjs-display/lib/base/TriangleSubGeometry");
import SubGeometryEvent	= require("awayjs-display/lib/events/SubGeometryEvent");
import BaseDataShape = require("./BaseDataShape");
import CubeDataShape = require("./CubeDataShape");
import PyramidDataShape = require("./PyramidDataShape");


import WorldDataElement	= require("./../../data/WorldDataElement");
import WorldDataSet	= require("./../../data/WorldDataSet");

class DataVisSubRenderable
{
    private _vertexPositionData : Float32Array;
    private _vertexHourlyData : Float32Array;
    private _indexData : Uint8Array;
    private _vertexPositionBuffer : WebGLBuffer;
    private _vertexHourlyBuffer : WebGLBuffer;
    private _indexBuffer : WebGLBuffer;
    private _numTriangles : number;
    private _gl : WebGLRenderingContext;
    private _minLat : number;
    private _maxLat : number;
    private _minLong : number;
    private _maxLong : number;

    constructor(dataPoints:WorldDataSet, startPoint:number, endPoint:number, baseShape?:BaseDataShape)
    {
        this.init(dataPoints, baseShape || new PyramidDataShape(), startPoint, endPoint);
    }

    public get minLat() : number { return this._minLat; }
    public get maxLat() : number { return this._maxLat; }
    public get minLong() : number { return this._minLong; }
    public get maxLong() : number { return this._maxLong; }

    public get numTriangles() : number
    {
        return this._numTriangles;
    }

    public dispose() : void
    {
        if (this._indexBuffer) this._gl.deleteBuffer(this._indexBuffer);
        if (this._vertexPositionBuffer) this._gl.deleteBuffer(this._vertexPositionBuffer);
        if (this._vertexHourlyBuffer) this._gl.deleteBuffer(this._vertexHourlyBuffer);

        this._indexBuffer = null;
        this._vertexPositionBuffer = null;
        this._vertexHourlyBuffer = null;
    }

    public getVertexPositionBuffer(gl:WebGLRenderingContext) : WebGLBuffer
    {
        this._gl = gl;
        if (!this._vertexPositionBuffer) {
            this._vertexPositionBuffer = gl.createBuffer();
            gl.bindBuffer(gl.ARRAY_BUFFER, this._vertexPositionBuffer);
            gl.bufferData(gl.ARRAY_BUFFER, this._vertexPositionData, gl.STATIC_DRAW);
            this._vertexPositionData = null;
        }
        return this._vertexPositionBuffer;
    }

    public getVertexHourlyBuffer(gl:WebGLRenderingContext) : WebGLBuffer
    {
        this._gl = gl;
        if (!this._vertexHourlyBuffer) {
            this._vertexHourlyBuffer = gl.createBuffer();
            gl.bindBuffer(gl.ARRAY_BUFFER, this._vertexHourlyBuffer);
            gl.bufferData(gl.ARRAY_BUFFER, this._vertexHourlyData, gl.STATIC_DRAW);
            this._vertexHourlyData = null;
        }
        return this._vertexHourlyBuffer;
    }

    public getIndexBuffer(gl:WebGLRenderingContext) : WebGLBuffer
    {
        if (!this._indexBuffer) {
            this._indexBuffer = gl.createBuffer();
            gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, this._indexBuffer);
            gl.bufferData(gl.ELEMENT_ARRAY_BUFFER, this._indexData, gl.STATIC_DRAW);
            this._indexData = null;
        }

        return this._indexBuffer;
    }

    // this is most likely going to be an array of structs
    private init(dataSet:WorldDataSet, baseShape:BaseDataShape, startPoint:number, endPoint:number):void
    {
        var dataPoints = dataSet.elements;
        var numPoints = dataPoints.length;

        if (numPoints == 0) {
            this._numTriangles = 0;
            return;
        }
        if (numPoints < endPoint) endPoint = numPoints;

        var baseIndices = baseShape.indices;
        var baseVertices = baseShape.vertices;
        var numBaseIndices = baseIndices.length;
        var numBaseVertices = baseVertices.length;

        numPoints = endPoint - startPoint;

        var vertexPositionData = this._vertexPositionData = new Float32Array(numPoints * numBaseVertices * 5);
        var vertexHourlyData = this._vertexHourlyData = new Float32Array(numPoints * numBaseVertices * 24);
        var indexData = this._indexData = new Uint16Array(numPoints * numBaseIndices);

        var vertexPosCounter : number = 0;
        var vertexHourCounter : number = 0;
        var indexCounter : number = 0;
        var indexOffset : number = 0;

        this._maxLat = this._minLat = dataPoints[0].latitude;
        this._maxLong = this._minLong = dataPoints[0].longitude;

        for(var i : number = startPoint; i < endPoint; ++i) {
            var dataPoint : WorldDataElement = dataPoints[i];
            var values : number[] = dataPoint.hourlyValues;
            var lat : number = dataPoint.latitude;
            var long : number = dataPoint.longitude;
            var j = 0;

            // find bounds:
            if (lat < this._minLat) this._minLat = lat;
            if (lat > this._maxLat) this._maxLat = lat;
            if (long < this._minLong) this._minLong = long;
            if (long > this._maxLong) this._maxLong = long;

            lat *= Math.PI / 180.0;
            long *= Math.PI / 180.0;

            while (j < numBaseVertices) {
                vertexPositionData[vertexPosCounter++] = baseVertices[j++];
                vertexPositionData[vertexPosCounter++] = baseVertices[j++];
                vertexPositionData[vertexPosCounter++] = baseVertices[j++];
                vertexPositionData[vertexPosCounter++] = lat;
                vertexPositionData[vertexPosCounter++] = long;

                for (var h = 0; h < 24; ++h) {
                    vertexHourlyData[vertexHourCounter++] = values[h];
                }
            }

            for (j = 0; j < numBaseIndices; ++j) {
                indexData[indexCounter++] = indexOffset + baseIndices[j];
            }

            indexOffset += numBaseVertices / 3;
        }

        this._numTriangles = indexData.length / 3;
    }
}
export = DataVisSubRenderable;