import MathConsts				= require("awayjs-core/lib/geom/MathConsts");
import Vector3D					= require("awayjs-core/lib/geom/Vector3D");
import Matrix3D					= require("awayjs-core/lib/geom/Matrix3D");

import DisplayObject			= require("awayjs-display/lib/base/DisplayObject");
import ControllerBase			= require("awayjs-display/lib/controllers/ControllerBase");

/**
 * Basically the hover controller but to correspond correctly to the lat/long calculations
 *
 * @see    away.containers.View
 */
class RotationController extends ControllerBase
{
    public _iCurrentLong:number = 0;
    public _iCurrentLat:number = 90;

    private _longitude:number = 0;
    private _latitude:number = 90;
    private _distance:number = 1000;
    private _steps:number = 8;
    private _wrapLongitude:boolean = false;
    private _lookAtTarget:Vector3D = new Vector3D(0, 0, 0, 1);

    public get lookAtTarget():Vector3D
    {
        return this._lookAtTarget;
    }

    public get steps():number
    {
        return this._steps;
    }

    public set steps(val:number)
    {
        val = (val < 1)? 1 : val;

        if (this._steps == val)
            return;

        this._steps = val;

        this.pNotifyUpdate();
    }

    /**
     * Rotation of the camera in degrees around the y axis. Defaults to 0.
     */
    public get longitude():number
    {
        return this._longitude;
    }

    public set longitude(val:number)
    {
        if (this._longitude == val)
            return;

        this._longitude = val;

        this.pNotifyUpdate();
    }

    /**
     * Elevation angle of the camera in degrees. Defaults to 90.
     */
    public get latitude():number
    {
        return this._latitude;
    }

    public set latitude(val:number)
    {
        if (val < -90) val = -90;
        else if (val > 90) val = 90;
        if (this._latitude == val)
            return;

        this._latitude = val;

        this.pNotifyUpdate();
    }

    /**
     * Distance between the camera and the specified target. Defaults to 1000.
     */
    public get distance():number
    {
        return this._distance;
    }

    public set distance(val:number)
    {
        if (this._distance == val)
            return;

        this._distance = val;

        this.pNotifyUpdate();
    }

    /**
     * Defines whether the value of the pan angle wraps when over 360 degrees or under 0 degrees. Defaults to false.
     */
    public get wrapLongitude():boolean
    {
        return this._wrapLongitude;
    }

    public set wrapLongitude(val:boolean)
    {
        if (this._wrapLongitude == val)
            return;

        this._wrapLongitude = val;

        this.pNotifyUpdate();
    }

    /**
     * Creates a new <code>HoverController</code> object.
     */
    constructor(targetObject:DisplayObject = null)
    {
        super(targetObject);

        //values passed in contrustor are applied immediately
        this._iCurrentLong = this._longitude;
        this._iCurrentLat = this._latitude;
    }

    public update(interpolate:boolean = true)
    {
        if (this._latitude != this._iCurrentLat || this._longitude != this._iCurrentLong) {

            this.pNotifyUpdate();

            if (this._wrapLongitude) {
                if (this._longitude < 0) {
                    this._iCurrentLong += this._longitude%360 + 360 - this._longitude;
                    this._longitude = this._longitude%360 + 360;
                } else {
                    this._iCurrentLong += this._longitude%360 - this._longitude;
                    this._longitude = this._longitude%360;
                }

                while (this._longitude - this._iCurrentLong < -180)
                    this._iCurrentLong -= 360;

                while (this._longitude - this._iCurrentLong > 180)
                    this._iCurrentLong += 360;
            }

            if (interpolate) {
                this._iCurrentLat += (this._latitude - this._iCurrentLat)/(this.steps + 1);
                this._iCurrentLong += (this._longitude - this._iCurrentLong)/(this.steps + 1);
            } else {
                this._iCurrentLong = this._longitude;
                this._iCurrentLat = this._latitude;
            }

            //snap coords if angle differences are close
            if ((Math.abs(this._latitude - this._iCurrentLat) < 0.01) && (Math.abs(this._longitude - this._iCurrentLong) < 0.01)) {
                this._iCurrentLat = this._latitude;
                this._iCurrentLong = this._longitude;
            }
        }

        var radX : number = this._iCurrentLat * Math.PI / 180.0;
        var radY : number = this._iCurrentLong * Math.PI / 180.0;
        var sinX : number = Math.sin(radX);
        var cosX : number = Math.cos(radX);
        var sinY : number = Math.sin(radY);
        var cosY : number = Math.cos(radY);

        var zAxisX = -sinY * cosX;
        var zAxisY = -sinX;
        var zAxisZ = cosY * cosX;

        this._pTargetObject.transform.position = new Vector3D(zAxisX * this._distance, zAxisY * this._distance, zAxisZ * this._distance);
        this._pTargetObject.lookAt(this._lookAtTarget);
    }
}

export = RotationController;