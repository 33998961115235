import BaseDataShape = require("./BaseDataShape");

class PyramidDataShape implements BaseDataShape
{
    vertices:number[] = [
        -.5, -.5, 0.0, .5, -.5, 0.0, .5, .5, 0.0, -.5, .5, 0.0,
        0, 0, 1.0
    ];

    indices:number[] = [
        0, 1, 4, 1, 2, 4, 2, 3, 4, 3, 0, 4
    ];

    constructor()
    {

    }
}
export = PyramidDataShape;