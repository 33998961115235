import ShaderObjectBase = require("awayjs-renderergl/lib/compilation/ShaderObjectBase");
import ShaderCompilerBase = require("awayjs-renderergl/lib/compilation/ShaderCompilerBase");
import MaterialGLBase = require("awayjs-renderergl/lib/materials/MaterialGLBase");
import MaterialPassGLBase = require("awayjs-renderergl/lib/passes/MaterialPassGLBase");

import GLSLCompiler = require("./GLSLCompiler");

class GLSLShaderObject extends ShaderObjectBase
{
    createCompiler(material: MaterialGLBase, materialPass: MaterialPassGLBase): ShaderCompilerBase
    {
        return new GLSLCompiler(material, materialPass, this);
    }
}
export = GLSLShaderObject;
