import EventDispatcher              = require("awayjs-core/lib/events/EventDispatcher");
import Quaternion				    = require("awayjs-core/lib/geom/Quaternion");
import Vector3D						= require("awayjs-core/lib/geom/Vector3D");
import RequestAnimationFrame		= require("awayjs-core/lib/utils/RequestAnimationFrame");
import PerspectiveProjection		= require("awayjs-core/lib/projections/PerspectiveProjection");
import View							= require("awayjs-display/lib/containers/View");
import Mesh							= require("awayjs-display/lib/entities/Mesh");
import ImageTexture					= require("awayjs-core/lib/textures/ImageTexture");
import PrimitiveSpherePrefab		= require("awayjs-display/lib/prefabs/PrimitiveSpherePrefab");
import PrimitivePlanePrefab		    = require("awayjs-display/lib/prefabs/PrimitivePlanePrefab");
import TriangleMethodMaterial		= require("awayjs-methodmaterials/lib/TriangleMethodMaterial");
import RaycastPicker				= require("awayjs-display/lib/pick/RaycastPicker");
import ShaderPicker				    = require("awayjs-renderergl/lib/pick/ShaderPicker");
import PickingCollisionVO			= require("awayjs-display/lib/pick/PickingCollisionVO");
import TriangleBasicMaterial        = require("awayjs-renderergl/lib/materials/TriangleBasicMaterial");
import JSPickingCollider			= require("awayjs-renderergl/lib/pick/JSPickingCollider");

import DataVisMaterial				= require("./rendering/DataVisMaterial");
import HaloMaterial				    = require("./rendering/HaloMaterial");
import GLSLRenderer					= require("./glsl/GLSLRenderer");
import StageHack					= require("./glsl/StageHack");
import DataVisRenderable			= require("./geometry/DataVisRenderable");
import ConeDataShape				= require("./geometry/ConeDataShape");

import RotationController			= require("./RotationController");
import WorldDataElement				= require("./../data/WorldDataElement");
import WorldDataSet				    = require("./../data/WorldDataSet");
import WorldDataEvent               = require("./../data/WorldDataEvent");

class Away3DDataVisView extends EventDispatcher
{
    private _view:View;
    private _cameraController:RotationController;
    private _timer:RequestAnimationFrame;
    private _lastLongitude:number;
    private _lastLatitude:number;
    private _lastMouseX:number = 0;
    private _lastMouseY:number = 0;
    private _move:boolean;
    private _mouseMoved:boolean;
    private _zoom:boolean;
    private _targetLocalLong:number;
    private _targetLocalLat:number;
    private _defaultGlobalCamDistance:number = 80;
    private _targetCamDistance:number;
    private _dataVisMaterial:DataVisMaterial;
    private _globalDataVisRenderable:DataVisRenderable;
    private _globalMaxValue:number;
    private _localDataVisRenderable:DataVisRenderable = null;
    private _rotationSpeed:number = .02;    // degrees / ms
    private _autoRotate:boolean = false;
    private _autoCycleTime:boolean = false;
    private _autoCycleTimeSpeed:number = 1/4000;    // h / ms
    private _viewTypeInertia:number = .93;
    private _sphereRadius:number = 30;
    private _zoomInitFingerDistance:number = 0;
    private _refZoomDistance:number = 0;
    private _minDistance:number = 40;
    private _maxDistance:number = 200;
    // this is NOT the same as the pivot point!
    private _lookAtTarget:Vector3D = new Vector3D();
    private _canvasContainer:HTMLDivElement;
    private _haloMesh:Mesh;

    constructor(divID : string)
    {
        super();
        this._canvasContainer = <HTMLDivElement> document.getElementById(divID);
        this.initEngine();
        this.initObject();
        this.initListeners();
    }

    get autoRotate() : boolean { return this._autoRotate; }
    set autoRotate(value : boolean) { this._autoRotate = value; }

    get autoRotateSpeed() : number { return this._rotationSpeed; }
    set autoRotateSpeed(value : number) { this._rotationSpeed = value; }

    // does this belong here, really? I suppose it will also involve updating the UI hour indicator?
    get autoCycleTime() : boolean { return this._autoCycleTime; }
    set autoCycleTime(value : boolean) { this._autoCycleTime = value; }

    get autoCycleTimeSpeed() : number { return this._autoCycleTimeSpeed; }
    set autoCycleTimeSpeed(value : number) { this._autoCycleTimeSpeed = value; }

    get hour() : number { return this._dataVisMaterial.hour; }
    set hour(value : number) { this._dataVisMaterial.hour = value; }


    set color(value : string) {
        if( this._dataVisMaterial) {
            this._dataVisMaterial.maxColor = parseInt("0x"+value);
            //this._dataVisMaterial.maxColor = parseInt("0xffffff");
            this._dataVisMaterial.minColor = parseInt("0x"+value);
            //this._dataVisMaterial.minColor = parseInt("0x000000");
        }
    }

    private initEngine()
    {
        StageHack.INIT();
        //create the view
        this._view = new View(new GLSLRenderer());
        this._view.backgroundColor = 0x202020;

        var child = this._view["_htmlElement"];

        document.body.removeChild(child);
        this._canvasContainer.appendChild(child);

        //create custom lens
        this._view.camera.projection = new PerspectiveProjection(70);
        this._view.camera.projection.far = 5000;
        this._view.camera.projection.near = 1;
        this._view.mousePicker = new RaycastPicker(false);

        //setup controller to be used on the camera
        this._cameraController = new RotationController(this._view.camera);
        this._cameraController.latitude = 0;
        this._cameraController.longitude = 0;
        this._cameraController.autoUpdate = false;
        this._targetCamDistance = this._cameraController.distance = this._defaultGlobalCamDistance;
        this._lookAtTarget.x = 0;
        this._lookAtTarget.y = 0;
        this._lookAtTarget.z = 0;
    }

    private getURLParameter(name) : string
    {
        return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.search)||[,""])[1].replace(/\+/g, '%20')) || null;
    }

    /**
     * Sets the global data. Required!
     */
    public setGlobalData(data:WorldDataSet):void
    {
        console.log("setGlobalData");
        if (this._globalDataVisRenderable) this._globalDataVisRenderable.dispose();

        this._globalDataVisRenderable = this.createDataVisRenderable(data);
        this._globalMaxValue = data.maxElementValue;
        this._dataVisMaterial.dataVisRenderable = this._globalDataVisRenderable;
        this._dataVisMaterial.maxValue = this._globalMaxValue;
    }

    /**
     * Focuses on a more local view (fe: country)
     */
    public showLocalView(data:WorldDataSet):void
    {
        if (this._localDataVisRenderable)
            this._localDataVisRenderable.dispose();

        this._localDataVisRenderable = this.createDataVisRenderable(data, .2);
        this._dataVisMaterial.dataVisRenderable = this._localDataVisRenderable;
        this._dataVisMaterial.maxValue = data.maxElementValue;

        this.focusCameraOnLocal();
    }

    private createDataVisRenderable(data:WorldDataSet, radius = .5, heightRatio = 10)
    {
        return new DataVisRenderable(data, new ConeDataShape(radius, radius * heightRatio, 10));
    }

    public showGlobalView():void
    {
        this._dataVisMaterial.dataVisRenderable = this._globalDataVisRenderable;

        if (this._localDataVisRenderable) {
            this._localDataVisRenderable.dispose();
            this._localDataVisRenderable = null;
        }

        this._targetCamDistance = this._defaultGlobalCamDistance;
    }

    public get isInLocalView():boolean
    {
        return this._localDataVisRenderable != null;
    }

    private initObject():void
    {
        var sphere = new PrimitiveSpherePrefab(this._sphereRadius / 3.0, 32, 24);
        var mesh = <Mesh>sphere.getNewObject();
        var image = document.getElementById("mapImage");

        var gradient : string = this.getURLParameter("gradient");

        mesh.material = this._dataVisMaterial = new DataVisMaterial(gradient == "1" || gradient == "true");
        mesh.pickingCollider = new JSPickingCollider(true);

        this._dataVisMaterial.texture = new ImageTexture(<HTMLImageElement> image, false);
        var color : string = this.getURLParameter("minColor");
        if (color)
            this._dataVisMaterial.minColor = parseInt("0x" + color);
        if (color)
            this._dataVisMaterial.maxColor = parseInt("0x" + this.getURLParameter("maxColor"));
        mesh.scaleX = mesh.scaleY = mesh.scaleZ = 3.0;
        this._view.scene.addChild(mesh);

        var plane = new PrimitivePlanePrefab(this._sphereRadius * 2.25, this._sphereRadius * 2.25, 1, 1, false, true);
        this._haloMesh = <Mesh>plane.getNewObject();
        var image = document.getElementById("haloImage");
        this._haloMesh.material = new HaloMaterial(new ImageTexture(<HTMLImageElement> image, false));
        this._haloMesh.mouseEnabled = false;
        this._haloMesh.mouseChildren = false;
        this._view.scene.addChild(this._haloMesh);
    }

    private focusCameraOnCountry(viewX:number, viewY:number):void {
        var collidingObject:PickingCollisionVO = this._view.mousePicker.getViewCollision(viewX, viewY, this._view);
        if(collidingObject){
            var pos = collidingObject.displayObject.sceneTransform.transformVector(collidingObject.localPosition);
            var lat:number = -Math.asin(-pos.y/this._sphereRadius)*180/Math.PI;
            var lon:number = Math.asin(-pos.x/(Math.cos(lat)*this._sphereRadius))*180/Math.PI;
            var worldEvent:WorldDataEvent = new WorldDataEvent(WorldDataEvent.FIND_LOCATION);
            worldEvent.lat = lat;
            worldEvent.long = lon;
            worldEvent.x = pos.x;
            worldEvent.y = pos.y;
            worldEvent.z = pos.z;
            worldEvent.sphereRadius = this._sphereRadius;
            this.dispatchEvent(worldEvent);
        }else{
            this.dispatchEvent(new WorldDataEvent(WorldDataEvent.SHOW_GLOBAL_LOCATION));
        }
    }

    private focusCameraOnLocal():void
    {
        var subRenderable = this._localDataVisRenderable.getSubRenderable(0);
        var minLat : number = subRenderable.minLat;
        var maxLat : number = subRenderable.maxLat;
        var minLong : number = subRenderable.minLong;
        var maxLong : number = subRenderable.maxLong;
        var len : number = this._localDataVisRenderable.numSubRenderables;

        for (var i : number = 1; i < len; ++i) {
            subRenderable = this._localDataVisRenderable.getSubRenderable(i);
            minLat = Math.min(subRenderable.minLat, minLat);
            maxLat = Math.max(subRenderable.maxLat, maxLat);
            minLong = Math.min(subRenderable.minLong, minLong);
            maxLong = Math.max(subRenderable.maxLong, maxLong);
        }

        var focusLong : number = (minLong + maxLong) * .5;
        var focusLat : number = (minLat + maxLat) * .5;

        // assuming there is no country that spans over half of the world in longitude ;)
        // NO, NOT EVEN YOU, RUSSIA!
        if (maxLong - minLong > 180)
            focusLong = 180 + focusLong;

        // TODO: use angular distance of lat/long bounds to know how far we can zoom
        var radX : number = focusLat * Math.PI / 180;
        var radY : number = focusLong * Math.PI / 180;
        var sinX : number = Math.sin(radX);
        var cosX : number = Math.cos(radX);
        var sinY : number = Math.sin(radY);
        var cosY : number = Math.cos(radY);

        var avgX = -sinY * cosX * this._sphereRadius;
        var avgY = -sinX * this._sphereRadius;
        var avgZ = cosY * cosX * this._sphereRadius;

        this._targetLocalLong = focusLong - 5;
        this._targetLocalLat = focusLat + 5;

        var maxAngleDistance:number = Math.max(maxLat-minLat, maxLong-minLong);
        maxAngleDistance-=20;
        if(maxAngleDistance<0) {
            maxAngleDistance = 0;
        }

        this._targetCamDistance = 40+Math.sqrt(maxAngleDistance);

        this._lookAtTarget.x = avgX;
        this._lookAtTarget.y = avgY;
        this._lookAtTarget.z = avgZ;
    }

    /**
     * Initialise the listeners
     */
    private initListeners():void
    {
        window.onresize  = (event) => this.onResize(event);

        this._canvasContainer.onmousedown = (event) => this.onMouseDown(event);
        this._canvasContainer.onmouseup = (event) => this.onMouseUp(event);
        this._canvasContainer.onmousemove = (event) => this.onMouseMove(event);

        var self = this;

        // some messy stuff because there's actually no ontouchstart etc properties?
        this._canvasContainer.addEventListener( 'touchstart', onTouchStart, false );
        this._canvasContainer.addEventListener( 'touchend', onTouchEnd, false );
        this._canvasContainer.addEventListener( 'touchmove', onTouchMove, false );


        function onTouchStart(event):void
        {
            self._mouseMoved = false;

            event.preventDefault();
            event.stopPropagation();

            self._move = false;
            self._zoom = false;

            switch ( event.touches.length ) {
                case 1:
                    self._move = true;
                    self._lastLongitude = self._cameraController.longitude;
                    self._lastLatitude = self._cameraController.latitude;
                    self._lastMouseX = event.touches[0].pageX;
                    self._lastMouseY = event.touches[0].pageY;
                    break;
                case 2:
                    self._zoom = true;
                    var dx = event.touches[ 0 ].pageX - event.touches[ 1 ].pageX;
                    var dy = event.touches[ 0 ].pageY - event.touches[ 1 ].pageY;
                    self._zoomInitFingerDistance = Math.sqrt( dx * dx + dy * dy );
                    self._refZoomDistance = self._cameraController.distance;
                    break;
            }
        }

        function onTouchEnd(event):void
        {
            event.preventDefault();
            event.stopPropagation();

            if(self._move) {
                if(self._mouseMoved) {
                    self._mouseMoved = false;
                }else{
                    self.focusCameraOnCountry(self._lastMouseX, self._lastMouseY);
                }
            }
            self._move = false;
            self._zoom = false;
        }

        function onTouchMove(event):void
        {
            event.preventDefault();
            event.stopPropagation();
            self._mouseMoved = true;

            switch ( event.touches.length ) {
                case 1:
                    if (self._move && !self.isInLocalView) {
                        self._cameraController.longitude = -0.3 * (event.touches[0].pageX - self._lastMouseX) + self._lastLongitude;
                        self._cameraController.latitude = -0.3 * (event.touches[0].pageY - self._lastMouseY) + self._lastLatitude;
                    }
                    break;
                case 2:
                    if (self._zoom) {
                        var dx = event.touches[ 0 ].pageX - event.touches[ 1 ].pageX;
                        var dy = event.touches[ 0 ].pageY - event.touches[ 1 ].pageY;
                        var distance = Math.sqrt( dx * dx + dy * dy );
                        self._targetCamDistance = self._cameraController.distance = Math.min(Math.max(self._refZoomDistance / distance * self._zoomInitFingerDistance, self._minDistance), self._maxDistance);
                    }
                    break;
            }
        }

        this.onResize();

        this._timer = new RequestAnimationFrame(this.onEnterFrame, this);
        this._timer.start();
    }

    /**
     * Render loop
     */
    private onEnterFrame(dt:number):void
    {
        var controllerLookAt = this._cameraController.lookAtTarget;
        if (!this.isInLocalView) {
            //update camera controler
            if (this._autoRotate)
                this._cameraController.longitude -= dt * this._rotationSpeed;

            // reset to 0 tilt
            //this._cameraController.latitude *= .75;
            controllerLookAt.x *= .75;
            controllerLookAt.y *= .75;
            controllerLookAt.z *= .75;
        }
        else {
            this._cameraController.latitude = this._cameraController.latitude * this._viewTypeInertia + this._targetLocalLat * (1.0 - this._viewTypeInertia);
            this._cameraController.longitude = this._cameraController.longitude * this._viewTypeInertia + this._targetLocalLong * (1.0 - this._viewTypeInertia);

            controllerLookAt.x = controllerLookAt.x * this._viewTypeInertia + this._lookAtTarget.x * (1.0 - this._viewTypeInertia);
            controllerLookAt.y = controllerLookAt.y * this._viewTypeInertia + this._lookAtTarget.y * (1.0 - this._viewTypeInertia);
            controllerLookAt.z = controllerLookAt.z * this._viewTypeInertia + this._lookAtTarget.z * (1.0 - this._viewTypeInertia);
        }

        //this._cameraController.latitude = 50;
        //this._cameraController.longitude = 50;

        this._cameraController.distance = this._cameraController.distance * this._viewTypeInertia + this._targetCamDistance * (1.0 - this._viewTypeInertia);

        this._cameraController.update();

        this._haloMesh.lookAt(this._view.camera.scenePosition);

        if (this._autoCycleTime) {
            this._dataVisMaterial.hour += dt * this._autoCycleTimeSpeed;

            while (this._dataVisMaterial.hour > 24) {
                this._dataVisMaterial.hour -= 24;
            }
        }

        //update view
        this._view.render();
    }

    /**
     * Mouse down listener for navigation
     */
    private onMouseDown(event):void
    {
        this._mouseMoved = false;
        if (this.isInLocalView) return;

        //this._view.cameraunproject

        this._lastLongitude = this._cameraController.longitude;
        this._lastLatitude = this._cameraController.latitude;
        this._lastMouseX = event.pageX;
        this._lastMouseY = event.pageY;
        this._move = true;
    }

    /**
     * Mouse up listener for navigation
     */
    private onMouseUp(event):void
    {
        this._move = false;
        if(this._mouseMoved) {
            this._mouseMoved = false;
        }else{
            this.focusCameraOnCountry(event.pageX, event.pageY);
        }
    }

    private onMouseMove(event)
    {
        this._mouseMoved = true;
        if (this._move) {
            this._cameraController.longitude = -0.3*(event.pageX - this._lastMouseX) + this._lastLongitude;
            this._cameraController.latitude = -0.3*(event.pageY - this._lastMouseY) + this._lastLatitude;
        }
    }

    /**
     * Mouse wheel listener for navigation
     */
    /*private onMouseWheel(event):void
     {
     if (this.isInLocalView) return;

     this._targetCamDistance -= event.wheelDelta * 5;

     if (this._targetCamDistance < 100)
     this._targetCamDistance = 100;
     else if (this._cameraController.distance > 2000)
     this._targetCamDistance = 2000;
     }*/

    /**
     * window listener for resize events
     */
    private onResize(event = null):void
    {
        this._view.y         = 0;
        this._view.x         = 0;
        this._view.width     = window.innerWidth;
        this._view.height    = window.innerHeight;
    }

}
export = Away3DDataVisView