import Matrix						= require("awayjs-core/lib/geom/Matrix");
import Matrix3D						= require("awayjs-core/lib/geom/Matrix3D");
import Matrix3DUtils				= require("awayjs-core/lib/geom/Matrix3DUtils");
import Texture2DBase			    = require("awayjs-core/lib/textures/Texture2DBase");

import Camera						= require("awayjs-display/lib/entities/Camera");
import MaterialBase					= require("awayjs-display/lib/materials/MaterialBase");

import Stage						= require("awayjs-stagegl/lib/base/Stage");

import MaterialPassData				= require("awayjs-renderergl/lib/pool/MaterialPassData");
import RenderableBase				= require("awayjs-renderergl/lib/pool/RenderableBase");
import ShaderObjectBase				= require("awayjs-renderergl/lib/compilation/ShaderObjectBase");
import ShaderRegisterCache			= require("awayjs-renderergl/lib/compilation/ShaderRegisterCache");
import ShaderRegisterData			= require("awayjs-renderergl/lib/compilation/ShaderRegisterData");

import HaloPass                    = require("./HaloPass");

class HaloMaterial extends MaterialBase
{
    private _haloPass: HaloPass;

    constructor(texture:Texture2DBase)
    {
        super();

        this._haloPass = new HaloPass();
        this._pAddScreenPass(this._haloPass);
        this._haloPass.texture = texture;
    }

    public get texture():Texture2DBase
    {
        return this._haloPass.texture;
    }

    public set texture(value:Texture2DBase)
    {
        this._haloPass.texture = value;
    }
}
export = HaloMaterial;