import ByteArray					= require("awayjs-core/lib/utils/ByteArray");

import AGALTokenizer				= require("awayjs-stagegl/lib/aglsl/AGALTokenizer");
import AGLSLParser					= require("awayjs-stagegl/lib/aglsl/AGLSLParser");
import IProgram						= require("awayjs-stagegl/lib/base/IProgram");


class ProgramGLSL implements IProgram
{
    private _gl:WebGLRenderingContext;
    private _program:WebGLProgram;
    private _vertexShader:WebGLShader;
    private _fragmentShader:WebGLShader;

    constructor(gl:WebGLRenderingContext)
    {
        this._gl = gl;
        this._program = this._gl.createProgram();
    }

    public upload(vertexProgram:ByteArray, fragmentProgram:ByteArray)
    {
        vertexProgram.position = 0;
        fragmentProgram.position = 0;
        var vertexString:string = this.byteArrayToString(vertexProgram);
        var fragmentString:string = this.byteArrayToString(fragmentProgram);

        this._vertexShader = this._gl.createShader(this._gl.VERTEX_SHADER);
        this._fragmentShader = this._gl.createShader(this._gl.FRAGMENT_SHADER);

        this._gl.shaderSource(this._vertexShader, vertexString);
        this._gl.compileShader(this._vertexShader);

        if (!this._gl.getShaderParameter(this._vertexShader, this._gl.COMPILE_STATUS)) {
            console.log("Error compiling vertex shader");
            throw new Error(this._gl.getShaderInfoLog(this._vertexShader));
        }

        this._gl.shaderSource(this._fragmentShader, fragmentString);
        this._gl.compileShader(this._fragmentShader);

        if (!this._gl.getShaderParameter(this._fragmentShader, this._gl.COMPILE_STATUS)) {
            console.log("Error compiling fragment shader");
            throw new Error(this._gl.getShaderInfoLog(this._fragmentShader));
        }

        this._gl.attachShader(this._program, this._vertexShader);
        this._gl.attachShader(this._program, this._fragmentShader);
        this._gl.linkProgram(this._program);

        if (!this._gl.getProgramParameter(this._program, this._gl.LINK_STATUS)) {
            throw new Error(this._gl.getProgramInfoLog(this._program));
        }
    }

    private byteArrayToString(ba:ByteArray) : string
    {
        ba.position = 0;
        var len = ba.readUnsignedInt();
        var str : string = "";

        for (var i = 0; i < len; ++i)
            str += String.fromCharCode(ba.readByte());

        return str;
    }

    public dispose()
    {
        this._gl.deleteProgram(this._program);
    }

    public focusProgram()
    {
        this._gl.useProgram(this._program);
    }

    public get glProgram():WebGLProgram
    {
        return this._program;
    }
}

export = ProgramGLSL;