import Matrix3D			= require("awayjs-core/lib/geom/Matrix3D");
import MaterialPassData = require("awayjs-renderergl/lib/pool/MaterialPassData");
import RendererBase		= require("awayjs-renderergl/lib/base/RendererBase");
import Texture2DBase    = require("awayjs-core/lib/textures/Texture2DBase");
import Camera			= require("awayjs-display/lib/entities/Camera");
import ContextWebGL     = require("awayjs-stagegl/lib/base/ContextWebGL");
import ProgramWebGL     = require("awayjs-stagegl/lib/base/ProgramWebGL");
import RenderableBase   = require("awayjs-renderergl/lib/pool/RenderableBase");
import Stage			= require("awayjs-stagegl/lib/base/Stage")
import SubGeometryBase = require("awayjs-display/lib/base/SubGeometryBase");
import TriangleSubGeometry = require("awayjs-display/lib/base/TriangleSubGeometry");
import GLSLMaterialPass = require("./../glsl/GLSLMaterialPass");


class GlobePass extends GLSLMaterialPass
{
    static VERTEX_CODE:string = "\
        precision mediump float;\n\
        \n\
        attribute vec4 position;\n\
        attribute vec2 uv;\n\
        attribute vec3 normal;\n\
        \n\
        uniform mat4 wvpMatrix;\n\
        uniform mat4 worldViewMatrix;\n\
        \n\
        varying vec2 uvVar;\n\
        varying vec3 viewNormal;\n\
        \n\
        void main() {\n\
            gl_Position = wvpMatrix * position;\n\
            viewNormal = mat3(worldViewMatrix) * normal;\n\
            uvVar = uv;\n\
        }\
    ";

    static FRAGMENT_CODE:string = "\
        precision mediump float;\n\
        \n\
        varying vec2 uvVar;\n\
        varying vec3 viewNormal;\n\
        uniform sampler2D globeSampler;\n\
        \n\
        void main() {\n\
            vec3 normal = normalize(viewNormal);\n\
            float rimFactor = 1.0 - clamp(-normal.z, 0.0, 1.0);\n\
            rimFactor = pow(rimFactor * 1.7, 4.0);\n\
            vec4 rimColor = vec4(1.0, 1.0, 1.0, 1.0);\n\
            vec4 globeColor = texture2D(globeSampler, uvVar);\n\
            gl_FragColor = mix(globeColor, rimColor, rimFactor);\n\
        }\
    ";

    private _wvpPosition : WebGLUniformLocation;
    private _worldViewMatrixPosition : WebGLUniformLocation;
    private _positionAttribute : number;
    private _uvAttribute : number;
    private _normalAttribute : number;
    private _matrix : Matrix3D = new Matrix3D();
    private _gl : WebGLRenderingContext;
    private _globeTexture : Texture2DBase;

    constructor()
    {
        super(GlobePass.VERTEX_CODE, GlobePass.FRAGMENT_CODE);
    }

    public get globeTexture() : Texture2DBase
    {
        return this._globeTexture;
    }

    public set globeTexture(value : Texture2DBase)
    {
        this._globeTexture = value;
    }

    public _iActivate(pass:MaterialPassData, renderer:RendererBase, camera:Camera)
    {
        renderer.activateMaterialPass(pass, camera);

        this._gl = (<ContextWebGL>renderer._pContext).gl();
        var program : WebGLProgram = (<ProgramWebGL>pass.programData.program)["_program"];

        if (!this._wvpPosition) {
            this._wvpPosition = this._gl.getUniformLocation(program, "wvpMatrix");
            this._worldViewMatrixPosition = this._gl.getUniformLocation(program, "worldViewMatrix");
            this._positionAttribute = this._gl.getAttribLocation(program, "position");
            this._uvAttribute = this._gl.getAttribLocation(program, "uv");
            this._normalAttribute = this._gl.getAttribLocation(program, "normal");
            var textureIndex = this._gl.getUniformLocation(program, "globeSampler");
            this._gl.uniform1i(textureIndex, 0);
        }

    }

    public setRenderState(pass:MaterialPassData, renderable:RenderableBase, stage:Stage, camera:Camera, viewProjection:Matrix3D)
    {
        this._matrix.copyFrom(renderable.renderSceneTransform);
        this._matrix.append(viewProjection);

        var f32 : Float32Array = new Float32Array(this._matrix.rawData);
        this._gl.uniformMatrix4fv(this._wvpPosition, false, f32);

        this._matrix.copyFrom(renderable.renderSceneTransform);
        this._matrix.append(camera.inverseSceneTransform);
        f32 = new Float32Array(this._matrix.rawData);
        this._gl.uniformMatrix4fv(this._worldViewMatrixPosition, false, f32);

        stage.activateBuffer(this._positionAttribute, renderable.getVertexData(TriangleSubGeometry.POSITION_DATA), renderable.getVertexOffset(TriangleSubGeometry.POSITION_DATA), "float3");
        stage.activateBuffer(this._uvAttribute, renderable.getVertexData(TriangleSubGeometry.UV_DATA), renderable.getVertexOffset(TriangleSubGeometry.UV_DATA), "float2");
        stage.activateBuffer(this._normalAttribute, renderable.getVertexData(TriangleSubGeometry.NORMAL_DATA), renderable.getVertexOffset(TriangleSubGeometry.NORMAL_DATA), "float3");
        stage.activateTexture(0, this._globeTexture);
        stage.context.drawTriangles(stage.getIndexBuffer(renderable.getIndexData()), 0, renderable.numTriangles);
    }

    public _iDeactivate(pass:MaterialPassData, renderer:RendererBase)
    {
        renderer.deactivateMaterialPass(pass);
    }
}

export = GlobePass;