import Matrix						= require("awayjs-core/lib/geom/Matrix");
import Matrix3D						= require("awayjs-core/lib/geom/Matrix3D");
import Matrix3DUtils				= require("awayjs-core/lib/geom/Matrix3DUtils");
import Texture2DBase			    = require("awayjs-core/lib/textures/Texture2DBase");

import Camera						= require("awayjs-display/lib/entities/Camera");
import MaterialBase					= require("awayjs-display/lib/materials/MaterialBase");

import Stage						= require("awayjs-stagegl/lib/base/Stage");

import MaterialPassData				= require("awayjs-renderergl/lib/pool/MaterialPassData");
import RenderableBase				= require("awayjs-renderergl/lib/pool/RenderableBase");
import ShaderObjectBase				= require("awayjs-renderergl/lib/compilation/ShaderObjectBase");
import ShaderRegisterCache			= require("awayjs-renderergl/lib/compilation/ShaderRegisterCache");
import ShaderRegisterData			= require("awayjs-renderergl/lib/compilation/ShaderRegisterData");
import TriangleMethodPass			= require("awayjs-methodmaterials/lib/passes/TriangleMethodPass");

import DataVisPass                  = require("./DataVisPass");
import GlobePass                    = require("./GlobePass");
import DataVisRenderable            = require("./../geometry/DataVisRenderable");

class DataVisMaterial extends MaterialBase
{
    private _globePass: GlobePass;
    private _dataVisPass: DataVisPass;

    constructor(useGradients : boolean = false)
    {
        super();

        this._globePass = new GlobePass();
        this._dataVisPass = new DataVisPass(useGradients);

        this._pAddScreenPass(this._globePass);
        this._pAddScreenPass(this._dataVisPass);
    }

    get dataVisRenderable() : DataVisRenderable
    {
        return this._dataVisPass.dataVisRenderable;
    }

    set dataVisRenderable(value : DataVisRenderable)
    {
        this._dataVisPass.dataVisRenderable = value;
    }

    public get hour() : number { return this._dataVisPass.hour; }
    public set hour(value : number) { this._dataVisPass.hour = value; }

    public get maxValue() : number { return this._dataVisPass.maxValue; }
    public set maxValue(value : number) { this._dataVisPass.maxValue = value; }

    public get texture():Texture2DBase
    {
        return this._globePass.globeTexture;
    }

    public set texture(value:Texture2DBase)
    {
        this._globePass.globeTexture = value;
    }

    public get minColor():number
    {
        return this._dataVisPass.minColor;
    }

    public set minColor(value:number)
    {
        this._dataVisPass.minColor = value;
    }

    public get maxColor():number
    {
        return this._dataVisPass.maxColor;
    }

    public set maxColor(value:number)
    {
        this._dataVisPass.maxColor = value;
    }
}
export = DataVisMaterial;