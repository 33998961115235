import AwayEvent                    = require("awayjs-core/lib/events/Event");
import URLLoader                    = require("awayjs-core/lib/net/URLLoader");
import URLLoaderDataFormat            = require("awayjs-core/lib/net/URLLoaderDataFormat");
import URLRequest                    = require("awayjs-core/lib/net/URLRequest");
import ParserUtils                    = require("awayjs-core/lib/parsers/ParserUtils");
import ImageTexture                    = require("awayjs-core/lib/textures/ImageTexture");

import Away3DDataVisView            = require("./away3d/Away3DDataVisView");

import WorldData                    = require("./data/WorldData");
import WorldDataSet                 = require("./data/WorldDataSet");
import WorldDataEvent               = require("./data/WorldDataEvent");
import WorldDataElement             = require("./data/WorldDataElement");

class GlobeFacade {
    private _dataVisSphere:Away3DDataVisView;
    private _viewType:string;
    private _handSet:string;
    private _handSetColor:string;
    private _timeType:string;
    private _worldData:WorldData;
    private _canvasTargetName:string;
    private _autoRotate:boolean;
    private _autoCycleTime:boolean;
    private _hour:number;
    private _highValue:number;
    private _showViewCallback:Function;
    /**
     * Constructor
     */
    constructor()
    {
    }

    /**
     */
    public init(canvasTargetName:string, handSet:string, handSetColor:string, viewType:string, highValue:number, showViewCallback:Function):void {
        this._canvasTargetName = canvasTargetName;
        this._handSet = handSet;
        this._handSetColor = handSetColor;
        this._viewType = viewType;
        this._highValue = highValue;
        this._showViewCallback = showViewCallback;

        this._worldData = new WorldData();
        this._worldData.addEventListener(WorldDataEvent.LOADED, (event:any) => this.onCountryLoaded(event));
        this._worldData.addEventListener(WorldDataEvent.NO_COUNTRY_DATA, (event:any) => this.onNoCountryLoaded(event));
        this._worldData.addEventListener(WorldDataEvent.NO_VENDOR_DATA, (event:any) => this.onNoVendorLoaded(event));
        this.loadViewData();
    }

    private loadViewData():void {
        this._worldData.load(this._viewType, this._handSet, this._timeType);
    }

    private onNoVendorLoaded(event:WorldDataEvent):void {
        console.warn("Nothing vendor data");
        this.showData(event.data);
    }

    private onNoCountryLoaded(event:WorldDataEvent):void {
        console.warn("No country data");
        this.showData(event.data, true);
    }

    private onCountryLoaded(event:WorldDataEvent):void {
        if (!this._dataVisSphere) {
            this._dataVisSphere = new Away3DDataVisView(this._canvasTargetName);
            this._dataVisSphere.hour = this._hour;
            this._dataVisSphere.autoCycleTime = this._autoCycleTime;
            this._dataVisSphere.autoRotate = this._autoRotate;
            this._dataVisSphere.addEventListener(WorldDataEvent.FIND_LOCATION, (event:any)=> this.onFindLocation(event));
            this._dataVisSphere.addEventListener(WorldDataEvent.SHOW_GLOBAL_LOCATION, (event:any)=> this.onShowGlobal(event));
            if(this._dataVisSphere) {
                this._dataVisSphere.color = this._handSetColor;
            }
        }

        this.showData(event.data);
    }

    private onShowGlobal(event:WorldDataEvent):void{
        this._showViewCallback(0);
    }

    private onFindLocation(event:WorldDataEvent):void{
        var id:string = this._worldData.getNearestLocation(event.x, event.y, event.z, event.sphereRadius);
        if(id == this._viewType) {
            id = "0";
        }
        this._showViewCallback(id);
    }

    private showData(data:WorldDataSet, forceGlobalView:Boolean = false):void
    {
        if (this._viewType == "0" || forceGlobalView) {
            this._dataVisSphere.setGlobalData(data);
            this._dataVisSphere.showGlobalView();
        }
        else {
            this._dataVisSphere.showLocalView(data);
        }
    }

    public setViewType(value:string, highValue:number):void
    {
        if (this._viewType == value) return;
        this._viewType = value;
        this._highValue = highValue;
        this.loadViewData();
    }

    public setTimeType(value:string):void
    {
        if (this._timeType == value) return;
        this._timeType = value;
        this.loadViewData();
    }

    public setHandSet(value:string, color:string):void
    {
        if (this._handSetColor != color) {
            this._handSetColor = color;
            if(this._dataVisSphere) {
                this._dataVisSphere.color = this._handSetColor;
            }
        }

        if (this._handSet != value) {
            this._handSet = value;
            this.loadViewData();
        }
    }

    public setTimelineRatio(t:number):void
    {
        this._hour = t * 24;

        if (this._dataVisSphere)
            this._dataVisSphere.hour = this._hour;
    }

    public setAutoCycleTime(value:boolean):void
    {
        this._autoCycleTime = value;

        if (this._dataVisSphere)
            this._dataVisSphere.autoCycleTime = value;

    }

    public setAutoRotate(value:boolean):void
    {
        this._autoRotate = value;

        if (this._dataVisSphere)
            this._dataVisSphere.autoRotate = value;
    }
}

window.onload = function ()
{
    // HACK, to fix
    window["globeFacade"] = new GlobeFacade();
}